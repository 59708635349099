import { useUserStore } from "@/stores/user"

export default defineNuxtRouteMiddleware(async (to, from) => {
    const userStore = useUserStore()
    const isLoggedIn = computed(() => userStore.isLoggedIn)
    const state = computed(() => userStore.state)
    const roles = computed(() => userStore.roles)
    if(isLoggedIn.value){
        //Admin check
        if(to.fullPath.includes('admin') && !roles.value?.includes('ROLE_ADMIN')) {
            return navigateTo({
                path: '/login',
                query: {
                    redirectTo: to.fullPath,
                    error: 'U heeft geen toegang tot deze pagina. Log in met een ander account.'
                }
            })
        }
        switch (state.value) {
            case "ACTIVE":
                return;
            case "DELETED":
                return navigateTo({
                    path: '/account_deleted',
                })
            case "EMAIL_NOT_VERIFIED":
                return navigateTo({
                    path: '/verify_email',
                })
            default:
                return navigateTo({
                    path: '/login',
                    query: {
                        redirectTo: from.fullPath,
                        error: 'Er ging iets mis. Probeer het opnieuw'
                    }
                })
        }
    }else{
        if(to.path == "/"){
            return;
        }else{
            return navigateTo({
                path: '/login',
                query: {
                    redirectTo: from.fullPath
                }
            })
        }
    }
})